import { Tile } from '/features/buildingBlocks/tile/Tile'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { useTranslate } from '/machinery/I18n'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './JobDetailTileGrid.css'

export function JobDetailTileGrid({
  tiles,
  layoutClassName = undefined,
  availableStyleContexts = undefined
}) {
  const topOfPage = useTopOfPage()
  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const compactGrid = viewportMd ? topOfPage : false

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {tiles.filter(tile => tile.representation?._type)?.map((tile, i) => {

        if (tile.representation._type === 'reward_calculator')
          return <RewardCalculatorTile {...{ tile, compactGrid }} />

        return (
          <li key={[i, tile._id].join()} className={cx(styles.gridItem, compactGrid && styles.compact)}>
            <Tile
              blankTile={topOfPage && viewportMd}
              {...{ availableStyleContexts, tile }}
            />
          </li>
        )
      })}
    </ul>
  )
}

function RewardCalculatorTile({ tile, compactGrid = undefined }) {
  const { __ } = useTranslate()
  const { linkRef, initialScale } = tile

  return (
    <li  className={cx(styles.componentRewardCalculatorTile, compactGrid && styles.compact)}>
      <ShowRewardCalculatorPortalFlowButton
        buttonType='tile'
        label={__`salary-checker`}
        dataX='link-to-salary-detail'
        layoutClassName={styles.calculatorLayout}
        {...{ linkRef, initialScale }}
      />
    </li>
  )
}
